import { Palette } from "@mui/icons-material";
import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#fb8600",
      light: "#fb9e33",
      dark: "#af5d00",
      contrastText: "#000",
    },
    secondary: {
      main: "#022233",
      light: "#344e5b",
      dark: "#011723",
      contrastText: "#fff",
    },
    text: {
      primary: "#fff",
      secondary: "#000",
    },
    divider: "#fff",
  },
  typography: {
    fontFamily: "Rubik",
    allVariants: {
      color: "#fff",
    },
  },
});
