import {
  Card,
  Typography,
  CardContent,
  Button,
  Box,
  Link,
} from "@mui/material";
import React from "react";

type Props = {
  title: string;
  description: string;
  gitHubUrl: string;
  imgUrl?: any;
  languages: string[];
};

export const ProjectCard = (props: Props) => {
  return (
    <Link sx={{ textDecoration: "none" }}>
      <Card
        sx={{
          maxWidth: 300,
          bgcolor: "secondary.dark",
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: "1rem",
            }}
          >
            <Typography variant="h5" component="div" color="primary.light">
              {props.title}
            </Typography>
            <CodeSnippetSVG />
          </Box>
          <Box sx={{ textAlign: "left" }}>
            <Typography variant="body2" paragraph>
              {props.description}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "1rem",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {props.languages.map((language, i) => (
                <Typography key={i} variant="caption">
                  {language}
                </Typography>
              ))}
            </Box>
            <Button size="small" href={props.gitHubUrl}>
              GitHub
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Link>
  );
};

const CodeSnippetSVG = ({ width }: { width?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "2rem"}
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5"
      />
    </svg>
  );
};
