import { Box, Divider, Typography, Grid } from "@mui/material";
import React from "react";
import { educationList } from "../data/cv-data";
import { EducationData } from "../data/types";

export const EducationGrid = () => {
  return (
    <Grid
      container
      sx={{
        justifyContent: "center",
        textAlign: "center",
        gap: ".5rem",
        mt: "3rem",
        gridTemplateRows: "minmax(0, 1fr)",
      }}
    >
      <Grid item xs={12}>
        <Divider sx={{ mb: "1rem" }}>
          <Typography variant="body1" fontWeight={600}>
            Utdanning
          </Typography>
        </Divider>
      </Grid>
      {educationList.map((education, index) => (
        <Education key={index} education={education} />
      ))}
    </Grid>
  );
};

type EducationProps = {
  education: EducationData;
};

const Education = ({ education }: EducationProps) => {
  const { school, begin, end, fieldOfStudy, kind } = education;
  return (
    <Grid item xs={12} md={3} sx={{ mt: { xs: "1rem" }, placeSelf: "center" }}>
      <Box sx={{ textAlign: "center", letterSpacing: "0.01rem" }}>
        <Typography variant="body2">{kind}</Typography>
        <Typography variant="body1" fontWeight={700} color="primary">
          {fieldOfStudy}
        </Typography>
        <Box sx={{ display: "flex", gap: "0.3rem", justifyContent: "center" }}>
          <Typography variant="body2" fontWeight={400}>
            {begin + " - " + end}
          </Typography>
          <Typography variant="body2">&#8226;</Typography>
          <Typography variant="body2">{school}</Typography>
        </Box>
      </Box>
    </Grid>
  );
};
