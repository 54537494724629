import React from "react";
import { Box, BoxProps } from "@mui/material";

type PageProps = {
  id: string;
  minHeight?: string;
  children?: React.ReactNode;
};

export const Page = ({
  id,
  minHeight,
  children,
  ...rest
}: PageProps & BoxProps) => {
  return (
    <Box
      id={id}
      sx={{
        minHeight: minHeight ? minHeight : "100vh",
        pt: "3rem",
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};
