import React from "react";
import { styled, TextField, TextFieldProps } from "@mui/material";

const StyledTF = styled(TextField)({
  color: "#fff",

  "& .MuiOutlinedInput-root": {
    color: "#fff",
    "& fieldset": {
      borderColor: "#fb9e33",
    },
    "&:hover fieldset": {
      borderColor: "#fb8600",
    },
  },
});

export const StyledTextField = (props: TextFieldProps) => {
  return <StyledTF {...props} />;
};
