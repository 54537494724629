// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.graphics {
  padding: 0 1rem;
  background: 50% 100% / 50% 50% no-repeat
    radial-gradient(ellipse at bottom, #fff, transparent, transparent);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 10vw;
  font-family: "Source Sans Pro", sans-serif;
  animation: reveal 3000ms ease-in-out forwards 200ms,
    glow 2500ms linear infinite 2000ms;
}

@keyframes glow {
  40% {
    text-shadow: 0 0 15px #fff;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/graphics.css"],"names":[],"mappings":"AAEA;EACE,eAAe;EACf;sEACoE;EACpE,6BAA6B;EAC7B,qBAAqB;EACrB,kBAAkB;EAClB,eAAe;EACf,0CAA0C;EAC1C;sCACoC;AACtC;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css?family=Source+Sans+Pro\");\n\n.graphics {\n  padding: 0 1rem;\n  background: 50% 100% / 50% 50% no-repeat\n    radial-gradient(ellipse at bottom, #fff, transparent, transparent);\n  -webkit-background-clip: text;\n  background-clip: text;\n  color: transparent;\n  font-size: 10vw;\n  font-family: \"Source Sans Pro\", sans-serif;\n  animation: reveal 3000ms ease-in-out forwards 200ms,\n    glow 2500ms linear infinite 2000ms;\n}\n\n@keyframes glow {\n  40% {\n    text-shadow: 0 0 15px #fff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
