import { Button, FormControl, Grid, Box } from "@mui/material";
import React, { useState } from "react";
import { StyledTextField } from "./StyledTextField";
import { init, send } from "emailjs-com";
import { RotatingLines } from "react-loader-spinner";

const initial = { from_name: "", from_email: "", message: "" };

type Props = {};

export const MailForm = (props: Props) => {
  const [toSend, setToSend] = useState(initial);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    send("service_7cnqmja", "template_22zdy7i", toSend, "fnYpZetv4c2q-9dg0")
      .then((response) => {
        setToSend(initial);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleChange = (e: any) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        marginTop: "1.5rem",
      }}
    >
      <form onSubmit={handleSubmit}>
        <Grid container spacing="1rem">
          <Grid item xs={12} md={6}>
            <StyledTextField
              label="Navn"
              type="text"
              name="from_name"
              required
              value={toSend.from_name}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledTextField
              label="E-post"
              type="email"
              name="from_email"
              required
              value={toSend.from_email}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              label="Melding"
              multiline
              type="text"
              required
              name="message"
              value={toSend.message}
              onChange={handleChange}
              rows={5}
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifySelf: { xs: "center", md: "start" },
              flexDirection: { xs: "column", md: "initial" },
            }}
          >
            <Button
              variant="contained"
              type="submit"
              sx={{
                color: "#fff",
                width: { xs: "fit-content" },
              }}
            >
              Send
            </Button>
            {isLoading && (
              <Box sx={{ padding: ".8rem" }}>
                <RotatingLines
                  strokeColor="orange"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="32"
                  visible={true}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
