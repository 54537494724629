export const downloadCV = () => {
  const link = document.createElement("a");
  link.download = `CV-Andre-Bjorgum.pdf`;
  link.href = "./CV-Andre-Bjorgum.pdf";
  link.click();
};

export const handleScroll = (htmlId) => {
  const el = document.getElementById(htmlId);
  if (el) {
    el.scrollIntoView({ behavior: "smooth" });
  }
};
