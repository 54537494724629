import { GitHub } from "@mui/icons-material";
import {
  Typography,
  Container,
  Box,
  Button,
  Grid,
  Divider,
  Link,
} from "@mui/material";
import { GitHubRound } from "../components/Icons";
import { Page } from "../components/Page";
import { ProjectCard } from "../components/ProjectCard";
import { downloadCV, handleScroll } from "../util/functions";
import { EducationGrid } from "../components/EducationGrid";
import { WorkGrid } from "../components/WorkGrid";

export const ExperiencePage = () => {
  return (
    <>
      <Page id="Experience">
        <Container>
          <Typography
            variant="h4"
            color="primary.main"
            sx={{ textAlign: "center" }}
            paragraph
          >
            Jobb & Utdanning
          </Typography>
          <EducationGrid />
          <WorkGrid />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="outlined"
              sx={{ display: { xs: "initial" }, m: "1rem auto" }}
              onClick={downloadCV}
            >
              Last ned CV
            </Button>
          </Box>
        </Container>
      </Page>
    </>
  );
};
