import React from "react";
import { Page } from "../components/Page";
import { Typography, Button, Container, Grid } from "@mui/material";
import { PageHeader } from "../components/Nav";
import { Box } from "@mui/system";
import { downloadCV, handleScroll } from "../util/functions";
import "./graphics.css";

export const HomePage = () => {
  const buttonLinks = [
    ["Experience", "Erfaring"],
    ["Technology", "Teknologier"],
    ["Projects", "Prosjekter"],
    ["Contact", "Kontakt"],
  ];

  return (
    <Page
      id="Home"
      style={{
        display: "flex",
        alignItems: "center",
        paddingTop: 0,
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "initial" },
          p: { xs: "2rem", md: "0" },
          alignItems: "center",
          justifyContent: "center",
          gap: "3rem",
          textAlign: { xs: "center" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            justifyContent: "center",
            color: "#fff",
            overflow: "hidden",
          }}
        >
          <Typography
            variant="h1"
            color="primary"
            fontWeight={100}
            style={{ fontSize: "3rem" }}
          >
            André Bjorgum
          </Typography>
          <div className="graphics">
            <Typography
              variant="h2"
              fontWeight={500}
              style={{ fontSize: "6rem" }}
            >
              Web utvikler
            </Typography>
          </div>
          <div>
            <Grid container spacing={2} sx={{ padding: "0 2rem" }}>
              {buttonLinks.map((page, i) => (
                <Grid
                  item
                  xs={6}
                  md={3}
                  sx={{ justifySelf: "center", alignSelf: "center" }}
                >
                  <Button
                    key={i}
                    variant="contained"
                    sx={{ color: "#fff", width: "100%" }}
                    onClick={() => handleScroll(page[0])}
                  >
                    {page[1]}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </div>
        </Box>
      </Container>
    </Page>
  );
};
