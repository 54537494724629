import { GitHub } from "@mui/icons-material";
import {
  Typography,
  Container,
  Box,
  Button,
  Grid,
  Divider,
  Link,
} from "@mui/material";
import { GitHubRound } from "../components/Icons";
import { Page } from "../components/Page";
import { ProjectCard } from "../components/ProjectCard";
import { handleScroll } from "../util/functions";

export const ProjectsPage = () => {
  return (
    <>
      <Page id="Projects">
        <Container>
          <Box sx={{ m: "0 auto", textAlign: "center" }}>
            <Typography variant="body1" color="primary.main">
              Prosjekt
            </Typography>
            <Typography variant="h4" paragraph>
              Trenger du hjelp med å lage nettside?
            </Typography>
            <Button
              variant="contained"
              onClick={() => handleScroll("Contact")}
              sx={{ color: "#fff" }}
            >
              Ta kontakt
            </Button>
            <Grid
              container
              sx={{
                mt: "3rem",
              }}
            >
              <Grid item xs={12}>
                <Divider>
                  <Typography variant="body1">Prosjekter</Typography>
                </Divider>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                justifyContent="center"
                sx={{ m: "1rem 0" }}
              >
                <ProjectCard
                  title="Pong"
                  gitHubUrl="https://github.com/Bjorginho/Pong"
                  description="Min implementasjon av Pong. Spillet er skrevet i Java og bruker biblioteket LibGdx for å generere Gui som gjør at spillet kan brukes i Android og Desktop"
                  imgUrl="../../pong.jpg"
                  languages={["Java"]}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                justifyContent="center"
                sx={{ m: "1rem 0" }}
              >
                <ProjectCard
                  title="Game Of Life"
                  gitHubUrl="https://github.com/Bjorginho/Pong"
                  description="Implementering av spillet Game of Life ved bruk av funksjonell programmering. Spillet er programmert i språket Haskell og var en oppgave på bacheloren"
                  imgUrl="../../game-of-life.png"
                  languages={["Haskell"]}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box>
                  <Link
                    href="https://github.com/Bjorginho"
                    sx={{ width: "fit-content" }}
                  >
                    <GitHubRound />
                  </Link>
                </Box>
                <Typography variant="body1">
                  Sjekk ut flere prosjekter på GitHub!
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Page>
    </>
  );
};
