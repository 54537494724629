import { Divider, Typography, Grid } from "@mui/material";
import React from "react";
import { workList } from "../data/cv-data";
import { WorkData } from "../data/types";

export const WorkGrid = () => {
  return (
    <Grid
      container
      sx={{
        justifyContent: "space-around",
        gap: ".5rem",
        mt: "3rem",
      }}
    >
      <Grid item xs={12}>
        <Divider sx={{ mb: "1rem" }}>
          <Typography variant="body1" fontWeight={600}>
            Relevant jobberfaring
          </Typography>
        </Divider>
      </Grid>
      {workList.map((work, index) => (
        <Work key={index} work={work} />
      ))}
    </Grid>
  );
};

type WorkProps = {
  work: WorkData;
};

const Work = ({ work }: WorkProps) => {
  const { company, begin, beginMonth, end, endMonth, role, description } = work;
  return (
    <Grid item xs={12} sx={{ mt: { xs: "1rem" } }}>
      <Grid
        container
        sx={{
          justifyContent: "space-around",
          gap: { xs: "1rem", md: "initial" },
          textAlign: { xs: "center", md: "initial" },
          mt: { xs: "1rem", md: "0" },
        }}
      >
        <Grid item xs={12} md={1}>
          <Typography variant="body2" color="primary" fontWeight={600}>
            {company}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="body2" fontWeight={400}>
            {beginMonth + " " + begin + " - " + endMonth + " " + end}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="body2">{role}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body2" fontWeight={300} paragraph>
            {description}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
