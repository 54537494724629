import "./style/App.css";
import { Box, Container, CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { HomePage } from "./pages/HomePage";
import { AboutPage } from "./pages/AboutPage";
import { ProjectsPage } from "./pages/ProjectsPage";
import { ContactPage } from "./pages/ContactPage";
import { ExperiencePage } from "./pages/ExperiencePage";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          bgcolor: "secondary.main",
          borderBottom: "1rem solid",
          borderColor: "primary.main",
          position: "relative",
        }}
      >
        <HomePage />
        <ExperiencePage />
        <AboutPage />
        <ProjectsPage />
        <ContactPage />
      </Box>
    </ThemeProvider>
  );
}

export default App;
