import { EducationData, WorkData } from "./types";

export const educationList: EducationData[] = [
  {
    school: "Universitetet i Bergen",
    begin: 2018,
    end: 2019,
    fieldOfStudy: "Informasjonsvitenskap",
    kind: "Årstudium",
  },
  {
    school: "Universitetet i Bergen",
    begin: 2019,
    end: 2022,
    fieldOfStudy: "Informatikk: Datateknologi",
    kind: "Bachelor",
  },
  {
    school: "NTNU Trondheim",
    begin: 2022,
    end: 2024,
    fieldOfStudy: "Informatics: Software Engineering",
    kind: "Master",
  },
];

export const workList: WorkData[] = [
  {
    company: "Norkart",
    begin: 2022,
    beginMonth: "Juni",
    end: 2022,
    endMonth: "August",
    role: "Full-stack utvikler (Internship)",
    description:
      "Tilbrakte sommeren sammen med andre studenter i Norkart på Skøyen i Oslo. \
    Jobbet i et team med en annen student og flere ansatte i Norkart, hvor vi hovedsakelig jobbet med sikkerhet av API- nøkler",
  },
  {
    company: "Fremtind Forsikring",
    begin: 2023,
    beginMonth: "Juni",
    end: 2023,
    endMonth: "August",
    role: "Full-stack utvikler (Internship)",
    description:
      "Denne sommeren skal jeg ha internship i Fremtind Forsikring AS.",
  },
];
