import { Box, Container, Grid, Typography } from "@mui/material";
import { Page } from "../components/Page";
import { AboutMeGrid } from "../components/AboutMeGrid";

const data = [
  "HTML",
  "CSS / Sass",
  "React",
  "TypeScript",
  "Payload",
  "Python",
  "Java",
  "Kotlin",
  "C#",
  "Haskell",
  "SQL / NoSQL",
];

export const AboutPage = () => {
  return (
    <Page id="Technology">
      <Container>
        <Typography
          sx={{ textAlign: "center", p: "2rem 0" }}
          variant="h3"
          color="primary"
        >
          Teknologier
        </Typography>
        <Grid container spacing={2} sx={{ p: "0 3rem" }}>
          {data.map((text) => (
            <Grid item xs={6} md={4} key={text}>
              <TechnologyBox text={text} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Page>
  );
};

type Props = {
  text: string;
};

const TechnologyBox = (props: Props) => {
  return (
    <Box
      sx={{
        p: ".5rem 1rem",
        bgcolor: "secondary.light",
        border: "1px solid #fff",
        textAlign: "center",
      }}
    >
      <Typography variant="body1" color="#fff">
        {props.text}
      </Typography>
    </Box>
  );
};
