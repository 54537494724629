import { FormControl, Box, Grid, Button, Container } from "@mui/material";
import { Page } from "../components/Page";
import { StyledTextField } from "../components/StyledTextField";
import { EmailOutlined } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { Footer } from "../components/Footer";
import { Facebook, LinkedIn } from "../components/Icons";
import { MailForm } from "../components/MailForm";

export const ContactPage = () => {
  return (
    <Page id="Contact" minHeight={"99vh"}>
      <Container>
        <Typography
          variant="h4"
          color="primary.main"
          sx={{ textAlign: "center" }}
          paragraph
        >
          Ønsker du å komme i kontakt?
        </Typography>
        <Box sx={{ textAlign: "center", marginTop: "1rem" }}>
          <EmailOutlined sx={{ fill: "#fff" }} fontSize="large" />
          <Typography variant="body1" paragraph>
            Fyll inn feltene under og legg ved en melding så får du svar av meg
            på e-post!
          </Typography>
          <MailForm />
        </Box>
        <Grid
          container
          sx={{
            textAlign: "center",
            width: { xs: "100%", md: "70%" },
            m: "auto",
            p: "0.5rem",
            pt: "0",
          }}
        >
          <Grid item xs={12} sx={{ mt: { xs: "3rem", md: "0" } }}>
            <Typography variant="overline" paragraph>
              Du kan også kontakte meg på
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: { xs: "1rem", md: "4rem" },
              mb: "1rem",
            }}
          >
            <Facebook />
            <LinkedIn />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
